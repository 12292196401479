<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('adaptation_request')"
                        :isColumns="true"
                        @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('adaptation_request')"
                              :isColumns="true"
                              @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear">
                <b-row>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm" v-model="datatable.queryParams.filter.student_number"
                                          v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('national_id_passport_no')">
                            <b-form-input type="text" size="sm" v-model="datatable.queryParams.filter.national_id"
                                          v-check-min-max-value></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('name')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.name"
                                          v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="3" xl="3">
                        <b-form-group :label="$t('surname')">
                            <b-form-input size="sm" v-model="datatable.queryParams.filter.surname"
                                          v-onlyAlphaAndSpace></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col xs="12" lg="6">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" lg="6">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="datatable.queryParams.filter.student_program_id"
                                               :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="3">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="6" md="6" lg="6" xl="3">
                        <b-form-group :label="$t('status')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.status"
                                                 code="course_transfer_status_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";
    import CourseTransferService from "@/services/CourseTransferService";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import qs from 'qs'
    import ExamScheduleService from "@/services/ExamScheduleService";

    export default {
        components: {
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
            FacultySelectbox,
            ProgramSelectbox,
            ParameterSelectbox,
        },
        metaInfo() {
            return {
                title: this.$t('university_courses')
            }
        },
        data() {
            return {
                universities: [],
                semesters: [],
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons:[
                                {
                                    text: this.$t('detail'),
                                    class: 'ri-search-eye-line',
                                    permission: "coursetransfer_show",
                                    callback:(row)=>{
                                        this.$router.push("/course/transfers/evaluation/" + row.id);
                                    }
                                }
                            ]
                        },
                        {
                            label: this.toUpperCase('request_number'),
                            field: 'id',
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('student_number'),
                            field: 'student_number',
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('national_id_passport_no'),
                            field: 'national_id',
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('name'),
                            field: 'name',
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('surname'),
                            field: 'surname',
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('faculty'),
                            field: ('faculty_name'),
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('program'),
                            field: ('program_name'),
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('input_type'),
                            field: ('registration_type'),
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('last_approve_date'),
                            field: 'last_approve_date',
                            sortable: false,
                        },
                        {
                            label: this.toUpperCase('status'),
                            field: ('status_name'),
                            sortable: true,
                            hidden: false,
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-id',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                form: {},
            }
        },
        created() {
            this.filterSet()
        },
        methods: {
            filterSet(){
                this.datatable.queryParams.filter = {
                    id: null,
                    student_number: null,
                    national_id: null,
                    name: null,
                    surname: null,
                    faculty_code: null,
                    student_program_id: null,
                    registration_type: null,
                    status: null,
                };
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key){
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return CourseTransferService.getAll(config)
                                              .then((response) => {
                                                  this.datatable.rows = response.data.data
                                                  this.datatable.total = response.data.pagination.total
                                              })
                                              .finally(() => {
                                                  this.datatable.isLoading = false;
                                              });
            },
            downloadExcel(){
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                ExamScheduleService.downloadAsExcel(config)
                    .then(res=>this._downloadFile(res,this.$t("university_courses")+'.xlsx'))
                    .catch(err=>this.showErrors(err))

            }
        }
    }
</script>

